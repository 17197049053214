/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
//@import "~bootstrap/dist/css/bootstrap.css";
// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Global fonts
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";

// Metronic styles (replace these path when using RTL css below)
@import "./assets/sass/style.angular.scss";

// For RTL, check this documentation https://keenthemes.com/metronic/?page=docs&section=angular-rtl
// @import "./assets/sass/style.angular.rtl.css";

// Default Layout themes
@import "./assets/sass/themes/layout/header/base/light.scss";
@import "./assets/sass/themes/layout/header/menu/light.scss";
@import "./assets/sass/themes/layout/brand/dark.scss";
@import "./assets/sass/themes/layout/aside/dark.scss";

@import "~dragula/dist/dragula.css";

// Header themes
// .header-base-light {
//     @import "./assets/sass/themes/layout/header/base/light.scss";
// }

// .header-base-dark {
//     @import "./assets/sass/themes/layout/header/base/dark.scss";
// }

// Header Menu themes
// .header-menu-light {
//     @import "./assets/sass/themes/layout/header/menu/light.scss";
// }
// .header-menu-dark {
//     @import "./assets/sass/themes/layout/header/menu/dark.scss";
// }

// Brand themes
.brand-dark {
  @import "./assets/sass/themes/layout/brand/dark.scss";
}

.brand-light {
  @import "./assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// .aside-dark {
//   @import "./assets/sass/themes/layout/aside/dark.scss";
// }

// .aside-light {
//   @import "./assets/sass/themes/layout/aside/light.scss";
// }

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

.angular-bootstrap-table {
  overflow-x: auto;
}

.angular-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead th.sortable-active {
    // color: #3699FF !important;
  }
}

.cursor-default {
  cursor: default !important;
}

.progress-modal {
  height: 3px;
  width: 100%;
}

.cdk-overlay-container {
  z-index: 2000 !important;
}

.merge-input-group {
  &:focus-within {
    .input-group-text {
      border-color: $primary;
    }
  }

  .input-group-text {
    background: transparent;
    border-radius: 0.42rem 0 0 0.42rem;
    border-right: none !important;
    padding-right: 0;

    &.is-invalid {
      border-color: $danger;
    }
  }

  .form-control {
    border-left: none !important;
  }
}

/* -------------------DRCULA DRAGE & DROP CSS  -----------------*/

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.input-group-transparent {
  .form-control {
    border-right: none !important;
  }

  .form-control-postfix {
    border: 1px solid rgba(147, 158, 170, 0.4);
    border-left: none;
    background: transparent;

    @include border-radius($input-border-radius, 0);

    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  &:has(.form-control:focus) {
    .form-control-postfix {
      border-color: $primary !important;
    }
  }
}

.input-dateTimePicker {
  .ngb-tp {
    width: fit-content;
    margin: auto;
  }
}

/* -------------------APP FORM changes -----------------*/
// .app-form-group,
// .na-container {
//   display: inline-block;

//   &.input-w-100 {
//     width: 100%;
//     max-width: 100%;
//     padding: 0 12.5px;
//   }

//   &.input-w-50 {
//     width: 50%;
//     max-width: 50%;
//     padding: 0 12.5px;
//   }
// }

.input-div-100 {
  width: 100%;
  max-width: 100%;
}

.input-div-50 {
  width: 50%;
  max-width: 50%;
}

.form-check-input {
  width: 1.3rem;
  height: 1.3rem;
}

.form-check-label {
  font-weight: 400 !important;
}

.btn-cancel {
  color: $gray-800 !important;
  background-color: $gray-300 !important;
  border-color: $gray-300 !important;
}

html {
  margin: 0;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $gray-300;
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px $gray-100;
  background-color: $gray-500;
}

.modal-confirm-size {
  max-width: 415px;
  max-height: 252px;
}

.svg-icon-user-card {
  width: 50px;
  height: 50px;
}

.custom-margin-top-user-management {
  margin-top: 1.8125rem;
}

.usermanagement-top-margin {
  margin-top: 1.75rem;
}

.dashbord-top-margin {
  margin-top: 1.6875rem;
}

@media only screen and (max-width: 968px) {
  div.dashbord-top-margin {
    margin-top: 0px;
  }
}

.hand-cursor {
  cursor: pointer;
  /* Change cursor to a hand icon */
  color: #3699ff;
}

.textFont {
  font-size: 1rem; // for 16px
}

.textfontEighteen {
  font-size: 1.125rem; // for 18px
}

.textFontfiftyfor {
  font-size: 3.375rem; // for 54px
}

.countCard {
  min-width: 12.4375rem; // for 199px
}

@media screen and (min-width: 992px) and (max-width: 1380px) {

  .countCard {
    min-width: 11rem; // for 199px
  }

  .countCard .card-body{
    padding-right: 1rem;
  }
}

.countCardMargin {
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
}

.totalUpoadsCountFont {
  font-size: 1.25rem; // for 20px
}

.textFontFourteen {
  font-size: 0.875rem; // for 14px
}

.chartFilter {
  color: #939eaa !important;
  font-weight: normal !important;
}

.chartFilter.active {
  color: #404244 !important;
  background-color: $gray-300 !important;
  border-color: $gray-300 !important;
  font-weight: normal !important;
}

.accessButtonFont {
  font-weight: 500 !important;
}

.emailLengthShot {
  max-width: 200px !important;
}

.pswrdMargin {
  margin-bottom: 0%;
}

.changePswrd {
  font-weight: 600;
  font-size: 1.5rem !important; // for 24px
}

.pwrdLabel {
  font-weight: 500 !important;
  font-size: 0.9375 !important;
}

.totalUpload {
  font-size: 54px;
  font-weight: 600;
}

.delButton {
  font-size: 0.9375rem !important; // for 15px
  font-weight: 500;
}

.docButton {
  text-align: right;
}

@media only screen and (max-width: 968px) {
  div.docButton {
    text-align: left;
  }
}

.delete-text {
  font-weight: 500;
  font-size: 16px;
}