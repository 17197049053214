// stylelint-disable declaration-no-important

@each $color, $value in $theme-colors {
  @include bg-variant(".bg-#{$color}", $value, true);
}

@if $enable-gradients {
  @each $color, $value in $theme-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value, true);
  }
}

.bg-white {
  background-color: $white !important;
}

.bg-transparent {
  background-color: transparent !important;
}
.accordion-button{
  background-color:white;
  font-size:medium ;
  border: none;
  padding-left: initial;
  padding-right: initial;
  font-weight: 500;
}

.accordion-button:hover {
  background-color: white; 
}